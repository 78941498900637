
import {defineComponent, onMounted, ref, watch} from 'vue'
import AetnaClaimService from "@/core/services/AetnaClaimService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {useToast} from "vue-toast-notification";
import Swal from "sweetalert2";

export default defineComponent({
  name: "LinkToInvoice",
  components: {   DateTimeFormat, EntityLoading},
  props: {
    aetnaId: {}
  },
  setup(props) {
    const invoiceId = ref('');
    const claimId = ref('')
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Aetna Claims',
        [
          {link: true, router: '/aetna-claims-new', text: 'List'},
          {link: false, router: '', text: 'Link To Invoice'}
        ]
      )
    })
    const data = ref<any>({});
    const invoices = ref<any>([]);
    const submitting = ref(false);
    const loading = ref(true);
    const loadingInvoice = ref(false);

    const loadClaimData = async (id) => {
      loading.value = true
      await AetnaClaimService.getClaimData(id).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })

    }
    loadClaimData(props.aetnaId);
    watch(() => props.aetnaId, (cb) => {
      loadClaimData(cb)
    })
    return {
      invoiceId,
      invoices,
      data,
      loadingInvoice,
      loading,
      claimId,
      submitting,
    }

  },
  methods: {
    onChange(invoices) {
      this.invoices = invoices
      this.invoiceId = ''
    },
    link() {
      if (!this.claimId) {
        useToast().warning("Please select claim", {position: 'top-right'})
        return;
      }
      this.submitting = true
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      AetnaClaimService.link(this.aetnaId, this.claimId).then(() => {
        useToast().success("Saved!", {position: 'top-right'})
        this.$router.push({name: 'aetna-claims'})
      }).finally(() => {
        this.submitting = false
        Swal.close()
      })
    },

  }
})
